:root {
  --bodyColor: '#f3f8ff';
}
body {
  margin: 0;
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bodyColor);
}

iframe {
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Tooltip container */
.tooltip.yesterday {
  display: inline-block;
}
.tooltip.yesterday .tooltiptext {
  display: none;
}

.tooltip:not(.yesterday) {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip:not(.yesterday) .tooltiptext {
  visibility: hidden;
  background-color: #fdfea4;
  font-size: 1.2rem;
  color: #000;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  bottom: 26px;
  min-width: 250px;
  width: auto;
  position: absolute;
  z-index: 1;
}
.tooltip:not(.yesterday) a {
  text-decoration: none;
  color: black;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:not(.yesterday):hover .tooltiptext {
  visibility: visible;
}

@media screen and (min-width: 600px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 54%;
  }
}

@media screen and (min-width: 700px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 54%;
  }
}

@media screen and (min-width: 900px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 56%;
  }
}

@media screen and (min-width: 1100px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 56%;
  }
}
@media screen and (min-width: 1200px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 62%;
  }
}
@media screen and (min-width: 1300px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 71%;
  }
}

@media screen and (min-width: 1400px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 80%;
  }
}
@media screen and (min-width: 1500px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 80%;
  }
}
@media screen and (min-width: 1600px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 82%;
  }
}
@media screen and (min-width: 1700px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 88%;
  }
}
@media screen and (min-width: 1800px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 95%;
  }
}
@media screen and (min-width: 1850px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 98%;
  }
}
@media screen and (min-width: 1900px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 100%;
  }
}
@media screen and (min-width: 1950px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 102%;
  }
}
@media screen and (min-width: 2000px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 107%;
  }
}
@media screen and (min-width: 2100px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 110%;
  }
}
@media screen and (min-width: 2500px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 126%;
  }
}
@media screen and (min-width: 2800px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 140%;
  }
}
@media screen and (min-width: 3000px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 150%;
  }
}
@media screen and (min-width: 3400px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 178%;
  }
}
@media screen and (min-width: 3800px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 200%;
  }
}
@media screen and (min-width: 5100px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 260%;
  }
}
@media screen and (min-width: 7600px) {
  .main-container,
  .MuiModal-root,
  .MuiSnackbar-root,
  .MuiTooltip-popper {
    zoom: 400%;
  }
}
