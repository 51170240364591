/* Custom tooltip */
.Links_tooltip {
  position: relative;
  display: inline-block;
  text-decoration: underline;
  text-decoration-color: blue;
}

.Links_tooltip:hover {
  text-decoration-color: black;
}

.Links_tooltip .Links_tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #555;
  background-color: #fff;
  border: 2px solid #e9e7e7;
  text-align: center;
  border-radius: 6px;
  padding: 10px 20px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.Links_tooltip .Links_tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.Links_tooltip:hover .Links_tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Export ranking tooltip */
.Ranking_tooltip {
  position: relative;
  display: inline-block;
}
.Ranking_tooltip .Ranking_tooltiptext {
  visibility: hidden;
  width: 65px;
  font-size: 16px;
  font-weight: bold;
  color: #555;
  background-color: #fff;
  border: 2px solid #e9e8e7;
  text-align: center;
  border-radius: 6px;
  padding: 10px 15px;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
  opacity: 0;
}

.Ranking_tooltip .Ranking_tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.Ranking_tooltip:hover .Ranking_tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Custom tooltip for ToolLinks */
.ToolLinks_tooltip {
  position: relative;
  display: inline-block;
}

.ToolLinks_tooltip .ToolLinks_tooltiptext {
  visibility: hidden;
  width: 135px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  background-color: #f7921d;
  border: 2px solid #e3e0d8;
  text-align: center;
  border-radius: 6px;
  text-transform: none;
  padding: 10px 15px;
  position: absolute;
  z-index: 1;
  bottom: 175%;
  left: 50%;
  margin-left: -90px;
  opacity: 0;
  transition: opacity 0.3s;
}

.ToolLinks_tooltip .ToolLinks_tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.ToolLinks_tooltip:hover .ToolLinks_tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* KPI PGT DATA tooltip */
.Kpi_tooltip,
.Alerts_tooltip {
  position: relative;
  display: inline-block;
}
.Kpi_tooltip .Kpi_tooltiptext,
.Alerts_tooltip .Alerts_tooltiptext {
  visibility: hidden;
  width: 135px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  background-color: #f7921d;
  border: 2px solid #e3e0d8;
  text-align: center;
  border-radius: 6px;
  text-transform: none;
  padding: 10px 15px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -90px;
  opacity: 0;
  transition: opacity 0.3s;
}
.Alerts_tooltip .Alerts_tooltiptext {
  width: 180px;
  z-index: 999;
}
.Kpi_tooltipIcon,
.Alerts_tooltipIcon {
  margin-left: 2px;
}

.Kpi_tooltip .Kpi_tooltiptext::after,
.Alerts_tooltip .Alerts_tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 55%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.Alerts_tooltip .Alerts_tooltiptext::after {
  left: 45%;
}

.Kpi_tooltip:hover .Kpi_tooltiptext,
.Alerts_tooltip:hover .Alerts_tooltiptext {
  visibility: visible;
  opacity: 1;
}
